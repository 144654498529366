:root {
  --mouse-x: 50%;
  --mouse-y: 50%;
}

.content {
  filter: contrast(100%) brightness(100%);
  background: linear-gradient(
      to bottom right,
      #e62f2f,
      rgba(255, 255, 255, 0.47)
    ),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='9.26' numOctaves='1' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  height: 100%;
} 

/* .emailoctopus-form {
  display: hidden;
  position: relative;
} */

/* .content {
  filter: contrast(100%) brightness(100%);
  background: linear-gradient(
      to bottom right,
      #dd3b22,
      #000000
    ),
    url("data:image/svg+xml,%3Csvg viewBox='500 500 332 332' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='10' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  height: 100%;
}  */

/* .content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('https://static.vecteezy.com/system/resources/previews/012/647/734/non_2x/noise-gradient-texture-grain-dot-stipple-background-black-pattern-grunge-fade-dot-noise-gradient-spray-vector.jpg'); 
  pointer-events: none; 
  opacity: 0.1; 
} */

/* .videoSection {
  filter: contrast(150%) brightness(100%);
  background: linear-gradient(
    147deg,
    #D7C0D0, 
    #F79AD3,
    #8E518D

  ),
	url("data:image/svg+xml,%3Csvg viewBox='0 0 341 341' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.00005' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  height: 100%
} */


