/* signup.css */

/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

/* Container for the signup form */
.signup-container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 40vh;
  margin: auto;
  /* margin: 50px auto; */
  /* padding: 20px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* border-radius: 8px; */
  align-items: center;
  /* background-color: rgb(44, 51, 51); */
  justify-content: center; /* Center vertically */
  /* height: 50vh; Full viewport height */
}

@media (max-width: 800px) {
  .signup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    /* padding: 5% 5% 5% 5%; */
  }
}

.signup-container p {
  align-items: center;
  color: rgb(255, 255, 255);
  justify-content: center;
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
}

/* .emailoctopus-form {
  display: hidden;
  position: relative;
} */

.octo-form {
  overflow: hidden;
  max-width: 100%;
}

