/* * {
  background-color: bisque;
} */

.eventsContainer {
  display: flex;
  flex-direction: column;
  background-color: #dd3c22;
  /* margin-left: 3vh;
  margin-right: 3vh; */
}

.eventsContainer h1 {
  display: flex;
  font-family: 'Veneer';
  font-size: 100px;
  font-weight: 200;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding: 0;
}

/* .eventListContainer{
  background-color: bisque;
  padding: 0px 4vh 0px 4vh;
  align-items: right;

}
 */

 :root {
  --mouse-x: 50%;
  --mouse-y: 50%;
}

.eventsContainer {
  height: 100%;
  filter: contrast(100%) brightness(100%);
  background: 
  linear-gradient(133deg, rgb(0, 255, 255), rgba(150,7,255,0.48)),
  
    radial-gradient(
      at var(--mouse-x) var(--mouse-y),
      rgba(255, 255, 255, 0.99),
      rgba(0, 0, 0, 0.99)
    ),
    url("data:image/svg+xml,%3Csvg viewBox='0 0 250 250' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='4.3' numOctaves='5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}


 