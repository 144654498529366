@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');

.banner {
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
  background: #00000001;
  position: absolute;
  color: #fff;
}

.scroll-text {
  font-family: "Instrument Serif";
  /* font-family: "Helevetica"; */
  /* font-style: italic; */
  display: block;
  font-size: 36px;
  /* color: black; */
  color: white;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  
  /* Start the item fully to the right */
  position: absolute;
  top: 0;
  left: 100%;

  /* Move the item from right to left */
  animation: scroll-left 15s linear infinite;
}

@keyframes scroll-left {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}
