@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Syne:wght@400..800&display=swap');


@font-face {
  font-family: "Akira";
  src: url('../../fonts/Akira Bold.otf');
}

@font-face {
  font-family: "LetterGothicStd";
  src: url('../..//fonts/LetterGothicStd.otf');
}


. {
  font-family: "LetterGothicStd";
}

#dice-event-list-widget {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  align-content: center;
  /* padding: 0vh 8vh 0vh 8vh; */
  width: 800px;
  margin: auto;
}

#dice-event-list-widget  a {
  /* padding: 0vh 0vh 0vh 0vh; */
  color: clear;
  font-family: 'LetterGothicStd';
  font-weight: 100;
  color: white;
}

h1 {
  font-family: 'Akira';
  font-size: 2em;
  font-weight: 200;
  padding: 10vh 0vh 0vh 0vh;
  color: white;
  text-align: center;
}

#dice-event-list-widget button {
  background-color: clear;
  color: clear;
  visibility: hidden;
}

.dice-widget {
  flex-direction: column-reverse;
  align-items: center;
  align-content: center;
  justify-content: center;
}

@media (max-width: 600px) { 
  .dice-widget {
      flex-direction: column;
      /* width: 1000px; */
      /* align-items: center;
      align-content: center; */
  }
  .dice-widget img {
    width: 90%;
    /* visibility: hidden; */
  }
  #dice-event-list-widget {
    padding: 0vh 0vh 0vh 0vh;
  }
  
}


@media (min-width: 1588px) {
  #dice-event-list-widget {
    max-height: 20%;
  }
}

@media (max-width: 1000px) {
  #dice-event-list-widget {
    max-width: 95%;
  }
}