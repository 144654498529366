/* Keep the container to group the button and the menu */
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');

@font-face {
  font-family: "Akira";
  src: url('../../../src/fonts/Akira Bold.otf');
}

@font-face {
  font-family: "LetterGothicStd";
  src: url('../../../src/fonts/LetterGothicStd.otf');
}

.menu-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.hamburger {
  font-size: 40px;
  background: none;
  color: #ffffff;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above other content */
}


.hamburger:hover {
  opacity: 0.7;
  color: rgb(237, 93, 93);
}

/* Position the menu dropdown directly below the button, and make it fixed as well */
.menu {
  position: fixed;
  top: 50px; /* Approximate height of the button, adjust as needed */
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: 'Akira';
  background-color: clear;
  border: 1px solid clear;
  /* padding: 10px; */
  width: 100%;
  height: 100%;
  font-size: 3em;
}

.menu a {
  color: white;
  transition: opacity 2.5s;
  text-decoration: none;
  max-width: 50%;
  font-family: "Akira";
}

.menu a:hover {
  color: rgb(231, 111, 111);
  opacity: 0.5;
}

/* ... other styles ... */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.99);
  /* background-color: rgba(8, 0, 123, 1); */
  z-index: 999;
  opacity: 0;  /* start invisible */
  transition: opacity 1.5s;
  pointer-events: none;
}

.show-backdrop {
  opacity: 1;
  /* transition: opacity 3s; */
  pointer-events: auto;
}

@media (max-width: 768px) { /* Updated breakpoint to 768px or whatever suits your design */
  .menu{
      font-size: 2em;
  }
 
  }

  .logoHamburger {
    position: relative;
    width: 50%;
    height: auto;
    margin: auto;
    /* filter: invert(100%); */
    max-width: 400px;
  }
  @media screen and (min-width:672px) {
    .hamburger {
      display: none;
    }
  }
