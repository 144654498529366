@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* Reset default styles */
/* html,
body {
  margin: 0;
  padding: 0;
} */

/* Global styles */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #dd3c22;
}

.masterContainerKps {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:  #BFACC8;
}

.masterContainerKps h1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0 0 0;
}

.blurbKPS {
  background-color: lightblue;
  padding: 4vh 0 4vh 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Instrument Sans', sans-serif;
  max-width: 80%;
  padding: 40px 0 0 0;
}

.about p {
  font-family: 'Instrument Sans', sans-serif;
  max-width: 80%;
  padding: 0 0 0 0;
}

.form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-family: 'Instrument Sans', sans-serif;
  max-width: 80%;
  width: 50%;
  padding: 10px 0 0 0;
}

.form h3 {
  align-items: center;
  text-align: left;
  font-family: 'Lato', sans-serif;
  font-size: 2.2vh;
  font-weight: 200;
  padding: 12px 0 0 0;
}

.form input {
  width: 100%%;
  padding: 5px 5px 5px 5px;
  border-radius: 15px;
}

.form button {
  padding: 0 0 0 0;
}

.submitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
}
/* Add your custom styles below */
