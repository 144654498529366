h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: black;
}

.masterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #cb0e04d6, #f07b7b, #ffffff);
  width: 98%;
  align-items: center;
} 

.displayContainer1 {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  margin-top: 0rem;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.inline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}



/* .emailoctopus-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
} */

/* .emailoctopus-form-wrapper button {
  background-color: #000000 !important;  
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;  
} */