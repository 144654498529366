@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@100&family=Tinos&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');


.blurb {
  font-family: "Instrument Serif" !important;
  display: flex;
  width: 80%;
  flex-direction: column;
  /* background-color: #A9EB7D; */
  /* padding: 0px 30px 100px 50px; */
  margin: auto;
  color: black;
  font-size: 3.5vw;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.blurb button {
  display: none;
  margin-top: 40px;
  margin-left: 0px;
  font-family: "IBM Plex Serif";
  font-weight: lighter;
  padding: 10px 20px 10px 20px;
  color: white;
  background-color: rgb(36, 35, 35);
  border-radius: 30px;
  font-size: 30px;
}

.blurb button:hover {
  background-color: grey;
  transition: background-color .25s ease-in-out,color .25s ease-in-out,transform .15s ease-in-out,outline .25s ease-in-out;
}


.banner{
  position: block;
  margin: 0;
  width: 100%;
  height: 50px;
  opacity: 1;
  text-align: center;
  justify-content: center;
  font-size: 120px;
}

.banner-text {
  position: absolute;
  top: 60%;
  left: 22%
}


@media (max-width: 768px) { /* Updated breakpoint to 768px or whatever suits your design */
  .blurb {
      font-size: 2em;
      width: 100%;
  }
}
