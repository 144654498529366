@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@100&family=Tinos&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans&family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');

@font-face {
  font-family: "Akira";
  src: url('./../src/fonts/Akira Bold.otf');
}

@font-face {
  font-family: "LetterGothicStd";
  src: url('./../src/fonts/LetterGothicStd.otf');
}

* {
  margin: 0;
  font-family: "LetterGothicStd" ;
  /* max-width: 100%; */
  /* min-width:  400px; */
  /* font-family: "Instrument Serif" "Times" ; */
  /* font-family: "HelveticaNeue-CondensedBold"; */
  /* font-style: con; */
  /* color: #f95858; */
  background-color: clear;
  overflow-x: hidden;
  /* box-sizing: border-box; */
}

body {
  font-family: "LetterGothicStd";
  /* background-color: #f4f4f4; */
  background-color: black;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 2;
  background-color: DDD8E880;
  top: 0;
  width: 100%;
  height: 15px;
  /* border-bottom: 1px solid #f2f2f2; */
  transition: transform 0.3s ease;
  transform: translateY(0);
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar:hover {
  transform: translateY(0);
}

.navbar h1 {
  color: #f1356d;
}

.navbar .links {
  font-size: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
}


.navbar a {
  /* font-family: "VT323"; */
  font-family: "LetterGothicStd";
  margin-left: 0px;
  text-decoration: none;
  color: white;
  padding: 6px;
}

.navbar a:hover {
  color: blue;
}

@media screen and (max-width:672px) {
  .navbar {
    display: none;
  }
}

.masterContainer {
  
  /* background-color: #e6128280;  */
  /* background-color: #dccb7680; */
  display: flex;
  flex-direction: column;
  backgroud-color: black;
  margin: auto;

}

.content {
  /* max-width: 750px; */
  /* margin: 40px auto; */
  padding: 0px 0px 0px 0px;
  background-color: #dd3c22;
}

/* .blog-preview {
  padding: 10px 16px;
  margin: 20px 0;
  border-bottom: 1px solid #fafafa;
}

.blog-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1);
}

.blog-preview h2 {
  text-align: center;
  font-size: 20px;
  color: #dc35c9;
  color: #2f00fead;
  margin-bottom: 8px;
} */

.mainVid {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* for 16:9 aspect ratio */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoWrapper #ytplayer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
}



  /* min-width: 100%;
  max-width: 100%; */
  /* align-items: center; */
  /* width: 100%; */
  /* height: 100vh; */


.mainVid iframe {
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

/* .mainVid::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(78, 78, 87, 0.4); 
} */

.splashContainer {
  width: 100%;
  height: 100%;
}

.splashLogo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #3498db;
}

.logo {
  position: absolute;
  width: 50%;
  height: auto;
  margin: auto;
  /* filter: invert(100%); */
  max-width: 400px;
}

.logo::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  0% {
      left: -100%;
  }
  100% {
      left: 100%;
  }
}

.videoSection {
  /* border-radius: 28px;  */
  position: relative;
  display: flex;
  overflow: hidden; 
  width: 100%;
  height: 100vh;
  max-width: 100%;
  background-color: #DDD8E880;

}

/* .video {
  min-width: 100%;
  max-width: 100%;
} */

.wid {
  /* height: 400px;
  width:800px; */
  /* font-family: 'Permanent Marker'; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: "LetterGothicStd";
  font-size: 20px;
  background: linear-gradient(#A9EB7D, #DE9AA3, #FD69B8);
  /* background: linear-gradient(#0b5f46, #6cdd9b, #ca6c8c); */
  align-items: center;
  width: 100%;
  /* height: 10000px; */
  /* padding-left: 60px; */
  /* min-height: 1000px; */
  /* max-width: 80%; */
  /* justify-content: space-evenly; */
}

.wid h1 {
  font-family: "Akira";
  display: relative;
  flex-direction: column;
  font-size: 100px;
  letter-spacing: 2px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  /* min-width: 100%; */
}

@media (min-width: 1588px) {
  .DiceWidgetHomePreview {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: 1982px) {
  .DiceWidgetHomePreview {
    padding-left: 20%;
    padding-right: 20%;
  }
}

@media (min-width: 2630px) {
  .DiceWidgetHomePreview {
    padding-left: 0%;
    padding-right: 0%;
  }
}


.dice-event-list-widget {
  color: black;
  display: flex;
  height: 100%;
  width:100%;
  max-width: 100%;
  z-index: 1;
}

.dice-event-list-widget img {
  width: 10%;
  height: 10%;

}

.dice-event-list-widget button {
  visibility: hidden;
}
/* .fade-in {
  transition: opacity 1s ease;

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
} */


.fade-in-background {
  transition: background-color 0.5s ease-in-out;
}

/* .fade-in {
  background-color: #8bbe70;

} */


/* index.css */

.fade-in-background-transition {
  transition: background-color 0.5s ease-in-out;
}




.kentPopExchange {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* background: linear-gradient(#dd3c22,#ffffff); */
  font-family: "HelveticaNeue";
  font-size: 20px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  min-height: 100px;
  max-width: 80%;
}

.kentPopExchange h1 {
  font-size: 100px;
  font-family: "Veneer";
  font-weight: 200;
  align-items: center;
}

.kentPopExchange p {
  display: flex;
  font-size: 1em;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.kentPopExchange button {
  display: fixed;
  align-items: center;
  color: white;
  /* justify-content: center; */
  background: black;
  border: black;
  border-radius: 80px;
  font-size: 0.7em;
  font-family: "Helvetica";
  padding: 15px 25px 15px 25px ;
}

.kentPopExchange button:hover {
  /* color:#A9EB7D; */
  transition: 0.4s;
  background-color: rgba(0, 0, 0, 0.685);

}

.bandFeatureContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 80px;
  width: 100%;
}

.bandFeatureText {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: fit-content;
  padding: 80px 40px 0px 100px;
  font-size: 2.5vw;
}

.bandFeatureText h2 {
  padding: 0px 10px 40px 0px;
  font-size: 4.5vw;
}

.bandFeatureText h3 {
  padding: 0px 10px 15px 0px;
  font-size: 3.5vw;
}

.bandFeatureImage {
  background-color: clear;
  padding: 15px 35px 35px 15px;
}

.bandFeatureImage img {
  width: 40vw;
}

.fade-in-text-section {
  opacity: 0;
  transition: opacity 2.5s 0s;
  width: 100%;

}

.fade-in-text-section.visible {
  opacity: 1;
}

.DicePreview {
  font-family: "LetterGothicStd";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.DicePreview h1 {
  font-family: 'Akira';
  font-size: 2em;
  font-weight: 200;
}


.col1 {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

.col2 {
  display: flex;
  flex-direction: column;
  padding: 140px 0px 0px 20px;

}

.col1 p, .col2 p {
  font-size: 20px;
}

.bandImage {
  width: 35vw;
  justify-content: left;
}

@media (max-width: 768px) { /* Updated breakpoint to 768px or whatever suits your design */
  .DicePreview {
      flex-direction: column;
  }
  .bandImage,
  .allShows button,
  .col1 p, .col2 p {
      width: 100%;
  }



  .col2 {
    padding: 20px 20px 0px 20px;
    font-size: 1em;
  }

  .col1 {
    padding: 0px 20px 0px 20px;
  }

  /* .bandImage {
    display: flex;
    max-width: 90%;
    align-items: center;
    justify-content: center;
  } */
}

@media (min-width: 1200px) {
  .mainVid video {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}


.band1 {
  padding: 0px 0px 20px 0px
}

.allShows {
  max-width: 100%;
}

.allShows button {
  display: block;
  width: 100%;
  /* max-width: 60%; */
  font-family: 'Instrument Serif';
  font-size: 1.5em;
  background-color: transparent;
  align-items: center;
  text-justify: center;
  border: none;
  padding-top: 70px;
  color: white;
}

.allShows button:hover {
  color: black;
}

.kpsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #f2c8ff; */
  background-color: none;
}

.kpsText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 85%;
}

#zf_div_MFgVCja2n9tdupzmVXHCCngCA_76T224qhhcQnF8KpE {
  width: 95%;
}

.kentPopExchangePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 50px 0px 50px 0px;
  max-height: 80%;
  min-height: 600px;
  margin: 0px 0px 0px 0px;
}

.kentPopExchangePreview p {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 80%;
}

.kentPopExchangePreview h1 {
  font-family: 'Veneer';
  font-size: 90px;
  font-weight: 200;
  align-items: center;
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.merchSection {
  font-family: "Akira";
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding-bottom: 12vh;
  /* background-color: #b9b0e5; */
}

.merchSection h1 {
  font-family: "Akira";
  font-size: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  border: 0;
  padding: 2vh 0vh 2vh;
  margin-block-start: 0em;
  color: white;
}

.merchSection img {
  position: relative;
  flex-direction: column;
  align-items: center;
  height: 600px;
  max-height: 100%; /* Adjust to fit your needs */
  max-width: 100%; /* Adjust to fit your needs */
  object-fit: contain; /* Add this line */
  outline: 65px dashed #000;
  outline-offset: -100px;
}
/* .merchSection::before {
  content: "-";
  position: absolute;
  top: 20%;
  left: 20%;
  right: -2px;
  bottom: -4px;
  border: 40px dashed #000;
  z-index: 2; 
}
 */

.dash1,
.dash2,
.dash3,
.dash4,
.dash5,
.dash6 {
  position: relative;
  background-color: black;
  /* height: 20px;
  width: 200px;  */
} 

.dash1 {
  top: 50px;
  left: 20px;
  z-index: 1;
  width: 150px;
  height: 0px;
}

.dash2 {
  top: 30px;
  left: 180px;
  width: 60px;
  height: 0px;
  z-index: 1;
}

.dash3 {
  top: 30px;
  left: 200px;
  width: 20px;
  z-index: 1;
}

.dash4 {
  /* width: 20px; 
  height: 7px; */
  display: none;
  top: 70px;
  left: 40px;
  z-index: 1;
}

.dash5 {
  display: none;
  top: 0%;
  right: 13%;
}

.dash6 {
  display: none;
  bottom: 0;
  right: 13%;
  top: 14%;
} 



/* .wavy {
  position: relative;
  width: 100%;
  height: 40vh; 
  background: transparent;
  overflow: hidden;
} */

/* .line {
  position: absolute;
  left: 0;
  font-size: 6.3em; 
  color: transparent;
  text-decoration-style: wavy;
  text-decoration-color: rgba(0, 0, 0, 0.823);
  text-decoration-line: underline;
  animation: animate 15s linear infinite; 
} */

.line:nth-child(1) {
  top: 0; /* Position for the first line */
}

.line:nth-child(2) {
  top: 40px; /* Adjust the position for the second line */
}

.line:nth-child(3) {
  top: 80px; /* Adjust the position for the third line */
}

.line:nth-child(4) {
  top: 120px; /* Adjust the position for the fourth line */
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


.image-container {
  position: relative;
  width: 40vw;
  max-width: 40vw;
  max-height: 40vw;
  height: 40vw;
  padding: 0vh 0vh 0vh 0vh;
  background-image: url('https://assets.bigcartel.com/product_images/379844007/BlackTshirt_PackShot.jpg?auto=format&fit=max&w=1400');
  background-size: cover; /* Change this line */
  background-position: center; /* Add this line */
  background-repeat: no-repeat; /* Add this line to prevent image from repeating */
  z-index: 0;
  outline: 4vh dashed #000; /* Change border to outline */
  outline-offset: -2vh; /* Add this line */
}
/* @media screen and (min-width: 700px) {
  .image-container, .overlay {
    padding: 10px;
  }
} */

.image-container .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.image-container:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Change color as needed */
}

@media (min-width: 1750px) {
  .image-container {
    max-height: 50vh;
    max-width: 50vh;
  }
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  visibility: hidden;
}

.image-container:hover .overlay-text {
  visibility: visible;
}

.playlistContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Add this line */
  padding-top: 8vh;
  height: 100%;
  max-width: 80%;
  padding: 10vh 5vh 10vh 5vh;
}

.playlistContainer p {
  padding-right: 2%;
  font-size: 1.5em;
  max-width: 95%;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.spotify-playlist {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  max-width: 100%;
}

.PlaylistMaster {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  padding-bottom: 1vh;
  width: 40%;
}

@media (max-width: 1025px) {
  .playlistContainer {
    display: flex;
    flex-direction: column;
    padding: 5% 5% 5% 5%;
  }
  .spotify-playlist {
    width: 100%;
  }
  .PlaylistMaster {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .playlistContainer {
    display: flex;
    flex-direction: row;
    padding: 15% 5% 5% 5%;
    
  }
}

@media (max-width: 800px) {
  .image-container {
    width: 80vw;
    max-width: 80vw;
    max-height: 80vw;
    height: 80vw;
    outline: none;
  }



  /* .line {
    visibility: hidden;
    display: none;
  }
  .wavy {
    visibility: inherit;
    display: none;
  } */
  .merchSection h1 {
    padding-bottom: 10%;
  }
}

.contactCopy {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 100%;
  /* padding: 15% 15% 15% 15%; */
}

.contactCopy p {
  font-size: 1em;
  max-width: 90%;
  /* align-items: center; */
  align-self: center;
  justify-content: left;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(44, 51, 51);
  align-items: center;
  margin-top: 1rem;
  max-width: 100%;
  margin-right: 0px;
  overflow-x: hidden; 
}

.footerSection1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-right: 0px;
  /* overflow-x: hidden; */
}

@media (max-width: 800px) {
  .footerSection1 {
    display: flex;
    flex-direction: column;
    /* padding: 5% 5% 5% 5%; */
  }
}

.footerSection2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}


@media (max-width: 800px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    padding: 5% 5% 5% 5%;
  }
}

.webLinks {
  font-family: 'Courier New', Courier, monospace;
  color: white;
  font-size: 1em;
  color: inherit;
  text-decoration: none;
}

.webLinks:visited {
  color: inherit;
}